import React, { Component } from "react"
import styled from "styled-components"
import {
    HiAnnotation,
    HiArchive,
    HiCheck,
    HiClock,
    HiDocument, HiDocumentDownload,
    HiFingerPrint,
    HiPencil, HiPlus,
    HiSwitchHorizontal,
    HiThumbUp,
    HiTrash,
    HiTrendingUp, HiX,
} from 'react-icons/hi';
import moment from "moment"
import Navigation from "../../components/Navigation"
import CreateWatchModal from "./CreateWatchModal";
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    Button,
    Box,
    Divider,
    AbsoluteCenter,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    Tag, Tooltip
} from '@chakra-ui/react';
import VerifyWatchModal from './VerifyWatchModal';
import RepairWatchModal from './RepairWatchModal';

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 32px;
    width: 100%;
  
    @media screen and (max-width: 1279px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
    }
    
    @media screen and (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

const StyledWatch = styled.div`
    width: 100%;
    height: 288px;
    box-sizing: border-box;
    padding: 16px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: opacity 0.3s;
    position: relative;
    
    .certified {
        position: absolute;
        right: 16px;
        bottom: -12px;
        height: 24px;
        display: flex;
        align-items: center;
        background: #1abc9c;
        color: white;
        padding: 0 12px;
        border-radius: 4px;
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
        font-size: 12px;
    }
  
    &:hover {
        opacity: 0.75;
    }
  
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        -webkit-user-drag: none;
    }
`

class Watch extends Component {
    render() {
        return <StyledWatch onClick={this.props.onClick}>
            <img src={this.props.src} />
            {/*{!this.props.waitingForVerification && this.props.certified && <div className="certified"><HiCheck style={{ fontSize: 16, marginRight: 4 }} />Authenzität bestätigt</div>}
            {!this.props.waitingForVerification && !this.props.certified && <div className="certified" style={{ backgroundColor: "#e67e22" }}><HiX style={{ fontSize: 16, marginRight: 4 }} />Authenzität unbestätigt</div>}
            {this.props.waitingForVerification && <div className="certified" style={{ backgroundColor: "#3498db" }}><HiClock style={{ fontSize: 16, marginRight: 4 }} />Warte auf Verifizierung</div>}*/}
        </StyledWatch>
    }
}

const AddWatch = styled.div`
    width: 100%;
    height: 288px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 48px;
    text-align: center;
    user-select: none;
    cursor: pointer;
    transition: border-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &:hover {
        border-color: rgba(0, 0, 0, 0.5);
    }
`

const Titles = styled.div`
    h1 {
        font-size: 24px;
        margin-top: 16px;
    }

    h2 {
        font-size: 18px;
        margin-bottom: 32px;
    }
`

export { Titles }

export default class extends Component {
    state = { drawerOpen: false, watches: null, watch: null }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        fetch(window.watchstorage.backendUrl + "/api/v1/watches", {
            method: "GET",
            credentials: "include",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${global.WatchStorage.session.token}`
            }
        }).then(async result => {
            if(result.status === 200) {
                const watches = await result.json()

                this.setState({ watches })
            } else {
                console.log(result)
            }
        }).catch(console.error)
    }

    render() {
        return <>
            <Drawer placement="right" onClose={() => {
                this.setState({ drawerOpen: false })
            }} isOpen={this.state.drawerOpen}>
                <DrawerOverlay />
                {this.state.drawerOpen && <DrawerContent>
                    <DrawerHeader borderBottomWidth='1px'>Uhr verwalten</DrawerHeader>
                    <DrawerBody>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr", gridGap: 16, marginTop: 12 }}>
                            {/*<Stat>
                                <StatLabel>Tagesaktueller Wert</StatLabel>
                                <StatNumber>4.129 EUR</StatNumber>
                                <StatHelpText>
                                    <StatArrow type='increase' />
                                    23.36%
                                </StatHelpText>
                            </Stat>*/}
                            {/*<Box position='relative' marginBottom="8px">
                                <Divider />
                                <AbsoluteCenter bg='white' px='4'>
                                    Status
                                </AbsoluteCenter>
                            </Box>*/}
                            {/*{this.state.watch.verifiedDate && <Tag size="lg" colorScheme='green' mb="8px" pt="4px" pb="4px">
                                <HiCheck style={{ marginRight: 8 }} /> Verifiziert am {moment(this.state.verifiedDate).format("DD.MM.YYYY")}
                            </Tag>}
                            {!this.state.watch.verifiedDate && <Tag size="lg" colorScheme='orange' mb="8px" pt="4px" pb="4px">
                                <HiCheck style={{ marginRight: 8 }} /> Authenzität unbestätigt
                            </Tag>}*/}
                            <Box position='relative' marginBottom="8px">
                                <Divider />
                                <AbsoluteCenter bg='white' px='4'>
                                    Daten ändern
                                </AbsoluteCenter>
                            </Box>
                            {/*<Tooltip hasArrow placement="left" label="Die Uhr kann nicht bearbeitet werden, da sie verifiziert ist.">
                                <Button width="100%" colorScheme="blue" leftIcon={<HiPencil />} isDisabled>Uhr bearbeiten</Button>
                            </Tooltip>*/}
                            <Tooltip hasArrow placement="left">
                                <Button width="100%" colorScheme="blue" leftIcon={<HiPencil />}>Uhr bearbeiten</Button>
                            </Tooltip>
                            {/*<Button width="100%" onClick={() => {
                                this.setState({ drawerOpen: false })
                                this.verifyWatchModal.open(this.state.watch)
                            }} colorScheme="green" isDisabled={this.state.watch.verifiedDate || this.state.watch.waitingForVerification} leftIcon={<HiFingerPrint />}>Verifizieren lassen</Button>*/}
                            {/*<Button width="100%" colorScheme="green" leftIcon={<HiThumbUp />}>Bewerten lassen</Button>*/}
                            {/*<Box position='relative' marginTop="16px" marginBottom="8px">
                                <Divider />
                                <AbsoluteCenter bg='white' px='4'>
                                    Verwaltung
                                </AbsoluteCenter>
                            </Box>
                            <Button width="100%" leftIcon={<HiDocumentDownload />}>Zertifikat herunterladen</Button>
                            <Button width="100%" leftIcon={<HiDocument />}>Dokumente verwalten</Button>
                            <Button width="100%" leftIcon={<HiTrendingUp />}>Wertentwicklung ansehen</Button>*/}
                            <Box position='relative' marginTop="16px" marginBottom="8px">
                                <Divider />
                                <AbsoluteCenter bg='white' px='4'>
                                    Aktionen
                                </AbsoluteCenter>
                            </Box>
                            {/*<Button width="100%" leftIcon={<HiSwitchHorizontal />} colorScheme="orange">Uhr übertragen</Button>*/}
                            <Button onClick={() => {
                                this.setState({ drawerOpen: false })
                                this.repairWatchModal.open(this.state.watch)
                            }} variant="outline" colorScheme="blue" width="100%" leftIcon={<HiAnnotation />}>Service anfragen</Button>
                            {/*<Button variant="outline" colorScheme="orange" width="100%" leftIcon={<HiArchive />}>Uhr archivieren</Button>*/}
                            <Button variant="outline" colorScheme="red" width="100%" leftIcon={<HiTrash />}>Uhr löschen</Button>
                        </div>
                    </DrawerBody>
                </DrawerContent>}
            </Drawer>
            <CreateWatchModal openRepairModal={watch => this.repairWatchModal.open(watch)} onRef={ref => this.createWatchModal = ref} refresh={() => this.fetch()} />
            <VerifyWatchModal onRef={ref => this.verifyWatchModal = ref} refresh={() => this.fetch()} />
            <RepairWatchModal onRef={ref => this.repairWatchModal = ref} refresh={() => this.fetch()} />
            <Navigation>
                <Titles>
                    <h1>Deine Uhren</h1>
                    <h2>Verwalten, verifizieren, versichern.</h2>
                </Titles>
                <Grid>
                    <AddWatch onClick={() => this.createWatchModal.open()}>
                        <HiPlus style={{ fontSize: "48px", marginBottom: 16, color: "#16a085" }} />
                        Neue Uhr zum Inventar hinzufügen
                    </AddWatch>
                    {this.state.watches !== null && this.state.watches.map((watch, index) => <Watch key={index} waitingForVerification={watch.waitingForVerification} src={watch.thumbnailImage || "/missing.jpg"} certified={!!watch.verifiedDate} onClick={() => {
                        this.setState({ drawerOpen: true, watch })
                    }} />)}
                </Grid>
            </Navigation>
        </>
    }
}
