import React, { Component } from "react";

import { createStandaloneToast } from '@chakra-ui/react'
import LoginPage from "./pages/Login"
import RegisterPage from "./pages/Register"
import DashboardPage from "./pages/Dashboard"
import ForgotPassword from "./pages/ForgotPassword"
import ResetPassword from "./pages/ResetPassword"
import ProfilePage from "./pages/Profile"

import styled from "styled-components"

import cookie from "cookie"

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom"
import { ClockLoader } from 'react-spinners';

const { ToastContainer, toast } = createStandaloneToast()

function App() {
  return <Router>
    <Routes>
      <Route path="*" element={<Navigate to="/login" />}/>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/dashboard" element={<RequireAuth><DashboardPage /></RequireAuth>} />
      <Route path="/profile" element={<RequireAuth><ProfilePage /></RequireAuth>} />
    </Routes>
    <ToastContainer />
  </Router>
}

class RequireAuth extends Component {
    state = {
        loading: true,
        auth: false,
        session: null
    }

    constructor(props) {
        super(props)

        window.toast = toast

        const cookies = cookie.parse(document.cookie)

        if(cookies && cookies.Authorization) {
            const bearer = cookies.Authorization

            fetch(window.watchstorage.backendUrl + "/api/v1/auth/refresh", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': bearer
                }
            }).then(result => {
                if(result.status === 201) return result.json()

                this.setState({ loading: false })

                return null
            }).then(result => {
                if(result) {
                    document.cookie = `Authorization=Bearer ${result.token}`

                    const session = result

                    global.WatchStorage.session = session

                    this.setState({ loading: false, auth: true, session })
                }
            })
        } else this.state.loading = false
    }

    render() {
        if(this.state.loading) return <StyledLoading>
            <ClockLoader size={72} />
        </StyledLoading>

        if(!this.state.auth) return <Navigate to="/login" replace />

        return this.props.children
    }
}

const StyledLoading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`

export default App;
