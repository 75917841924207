import { extendTheme } from '@chakra-ui/react'
import {StepsStyleConfig as Steps} from "chakra-ui-steps";

export default extendTheme({
    colors: {

    },
    components: {
        Steps,
    },
})
