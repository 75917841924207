import React, { Component } from "react"

import { Titles } from '../Dashboard'
import Navigation from "../../components/Navigation"
import styled from "styled-components"

const Container = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
`

export default class extends Component {
    render() {
        return <>
            <Navigation>
                <Titles>
                    <h1>Dein Profil</h1>
                    <h2>Verwalte deine Uhren in Form eines sicheren Inventars.</h2>
                </Titles>
                <Container>

                </Container>
            </Navigation>
        </>
    }
}
