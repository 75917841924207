import React, { Component, useRef, useState } from 'react';
import styled from "styled-components"

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button, Alert, AlertIcon,
} from '@chakra-ui/react';
import { Camera } from "react-camera-pro";
import { HiCamera, HiSave } from 'react-icons/hi';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const Control = styled.div`
  top: 0;
  position: fixed;
  display: flex;
  right: 0;
  width: 20%;
  min-width: 130px;
  min-height: 130px;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  box-sizing: border-box;
  flex-direction: column-reverse;

  @media (max-aspect-ratio: 1/1) {
    flex-direction: row;
    bottom: 0;
    width: 100%;
    height: 20%;
  }

  @media (max-width: 400px) {
    padding: 10px;
  }
`

const StyledButton = styled.button`
  outline: none;
  color: white;
  opacity: 1;
  background: transparent;
  background-color: transparent;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-image: none;
  padding: 0;
  text-shadow: 0px 0px 4px black;
  background-position: center center;
  background-repeat: no-repeat;
  pointer-events: auto;
  cursor: pointer;
  z-index: 2;
  filter: invert(100%);
  border: none;

  &:hover {
    opacity: 0.7;
  }
`

const TakePhotoButton = styled(StyledButton)`
  background: url('https://img.icons8.com/ios/50/000000/compact-camera.png');
  background-position: center;
  background-size: 50px;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  border: solid 4px black;
  border-radius: 50%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`

const ChangeFacingCameraButton = styled(StyledButton)`
  background: url(https://img.icons8.com/ios/50/000000/switch-camera.png);
  background-position: center;
  background-size: 40px;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  padding: 40px;
  &:disabled {
    opacity: 1;
    cursor: default;
  }
  @media (max-width: 400px) {
    padding: 40px 5px;
  }
`

const ResponsiveCameraWrapper = styled.div`
    width: min(80%, calc(100% - 130px));
  
    @media screen and (max-width: 908px) {
        width: 100%;
    }
`

function CameraComponent(props) {
    const [numberOfCameras, setNumberOfCameras] = useState(0)
    const camera = useRef(null)
    const hour = props.hour
    const minute = props.minute

    return (
        <Wrapper>
            <ResponsiveCameraWrapper style={{ width: "min(80%, calc(100% - 130px))", display: "flex", zIndex: 10, justifyContent: "center", flex: 1, position: "fixed", alignItems: "center", height: "100%" }}>
                <div style={{ background: "white", position: "absolute", zIndex: 10, bottom: "3%", maxWidth: "80%", padding: 16, borderRadius: 5, left: "50%", transform: "translateX(-50%)" }}>
                    Stelle deine Uhr auf <b>{String(hour).padStart(2, "0")}:{String(minute).padStart(2, "0")} Uhr</b> ein und schieße ein Foto mit gezogener Krone.
                </div>
                <div style={{ width: "30%", paddingTop: "calc(30% - 8px)", zIndex: 10, borderRadius: "100%", border: "4px solid rgba(255, 255, 255, 0.5)", position: "relative" }} className="clock">
                    <div className="hour" style={{ width: "2%", transformOrigin: "bottom center", position: "absolute", left: "49%", top: "20%", borderRadius: "15px", height: "30%", background: "white", transform: "rotate(" + ((hour / 12) * 360) + "deg)" }}></div>
                    <div className="minute" style={{ width: "2%", transformOrigin: "bottom center", position: "absolute", left: "49%", top: "10%", borderRadius: "15px", height: "40%", background: "white", transform: "rotate(" + ((minute / 60) * 360) + "deg)" }}></div>
                    <div className="dot" style={{ width: "4%", height: "4%", position: "absolute", left: "48%", top: "48%", borderRadius: "100%", background: "white" }}></div>
                </div>
            </ResponsiveCameraWrapper>
            <Camera facingMode="environment" ref={camera} aspectRatio="cover" numberOfCamerasCallback={setNumberOfCameras}>

            </Camera>
            <Control>
                <TakePhotoButton
                    onClick={() => {
                        if (camera.current) {
                            const photo = camera.current.takePhoto()

                            props.onPhotoTaken(photo)
                        }
                    }}
                />
                <ChangeFacingCameraButton
                    disabled={numberOfCameras <= 1}
                    onClick={() => {
                        if (camera.current) {
                            camera.current.switchCamera()
                        }
                    }}
                />
            </Control>
        </Wrapper>
    );
}

const initialState = {
    saveLoading: false,
    cameraModalOpen: false
}

export default class extends Component {
    state = {}

    constructor(props) {
        super(props)

        this.onClose = this.onClose.bind(this)
        this.open = this.open.bind(this)

        this.state = { ...initialState }
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    open(watch) {
        const keys = Object.keys(this.state)
        const stateReset = keys.reduce((acc, v) => ({ ...acc, [v]: undefined }), {})

        this.setState({ ...stateReset, ...initialState, open: true, watch, id: null, hour: 0, minute: 0 })
    }

    onClose() {
        this.setState({ open: false })
    }

    takePhoto() {
        this.setState({ cameraModalOpen: true, open: false })
    }

    render() {
        if(this.state.cameraModalOpen) return <CameraComponent hour={this.state.hour} minute={this.state.minute} onPhotoTaken={image => {
            this.setState({ cameraModalOpen: false, open: true, image, saveLoading: false })
        }} />

        return <Modal closeOnOverlayClick={false} isOpen={this.state.open} onClose={this.onClose}>
            <ModalOverlay />
            <ModalContent maxW="1100px">
                <ModalHeader>Uhr verifizieren</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {!this.state.image && <Alert status='info'>
                        <AlertIcon />
                        Im folgenden Schritt wirst du darum gebeten, deine Uhr auf eine zufällig generierte Uhrzeit einzustellen, um dann von ihr ein Foto zu schießen. Bitte halte deine Uhr bereit und klicke auf 'Foto schießen', sobald du bereit bist.
                    </Alert>}
                    {!!this.state.image && <img style={{ width: "100%" }} src={this.state.image} />}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.onClose} variant='ghost' style={{ marginRight: 16 }}>
                        Abbrechen
                    </Button>
                    <Button leftIcon={this.state.image ? <HiSave /> : <HiCamera />} isLoading={this.state.saveLoading} colorScheme="green" onClick={() => {
                        if(!this.state.image) {
                            this.setState({ saveLoading: true })

                            fetch(window.watchstorage.backendUrl + "/api/v1/watchTokens", {
                                method: "POST",
                                credentials: "include",
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${global.WatchStorage.session.token}`
                                },
                                body: JSON.stringify({
                                    watchId: this.state.watch.id
                                })
                            }).then(result => result.json()).then(({ hour, minute, id }) => {
                                this.setState({ hour, minute, id, saveLoading: false }, () => this.takePhoto())
                            })
                        } else {
                            this.setState({ saveLoading: true })

                            fetch(window.watchstorage.backendUrl + "/api/v1/watchTokens/verify", {
                                method: "POST",
                                credentials: "include",
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${global.WatchStorage.session.token}`
                                },
                                body: JSON.stringify({
                                    id: this.state.id,
                                    image: this.state.image
                                })
                            }).then(data => {
                                if(data.status === 200 || data.status === 201) {
                                    this.setState({ open: false })
                                    this.props.refresh()

                                    window.toast({
                                        title: 'Verifizierung erfolgreich beantragt',
                                        description: 'Die Verifizierung deiner Uhr wurde erfolgreich beantragt. Du erhältst eine Benachrichtigung, sobald die Verifizierung erfolgt ist.',
                                        status: 'success',
                                        duration: 9000,
                                        isClosable: true
                                    })
                                } else {
                                    this.setState({ saveLoading: false })

                                    window.toast({
                                        title: 'Verifizierung fehlgeschlagen',
                                        description: 'Bei der Verifizierung deiner Uhr ist ein unbekanntes Problem aufgetreten. Bitte versuche es später erneut.',
                                        status: 'error',
                                        duration: 9000,
                                        isClosable: true
                                    })
                                }
                            })
                        }
                    }} mr={3}>{this.state.image ? "Verifizierung beantragen" : "Foto schießen"}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    }
}
