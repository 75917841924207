import React, { useEffect, useState } from 'react';
import { useCombobox } from "downshift";
import { Input, List, ListItem, Flex, Text, IconButton } from "@chakra-ui/react";

const ComboboxInput = React.forwardRef(({ ...props }, ref) => {
    return <Input {...props} ref={ref} size="lg" />;
});

const ComboboxList = React.forwardRef(({ isOpen, ...props }, ref) => {
    return <List display={isOpen ? null : "none"} py={2} {...props} ref={ref} />;
});

const ComboboxItem = React.forwardRef(
    ({ itemIndex, highlightedIndex, ...props }, ref) => {
        const isActive = itemIndex === highlightedIndex;

        return (
            <ListItem
                transition="background-color 220ms, color 220ms"
                bg={isActive ? "#dfe2ed" : null}
                px={4}
                py={2}
                cursor="pointer"
                {...props}
                ref={ref}
            />
        );
    }
);

export default function Combobox(props) {
    const [inputItems, setInputItems] = useState(props.items || []);

    const {
        isOpen,
        openMenu,
        selectedItem,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        highlightedIndex,
        getItemProps,
        setInputValue
    } = useCombobox({
        items: inputItems || [],
        selectedItem: props.selectedItem,
        inputValue: props.inputValue,
        onSelectedItemChange: data => {
            if(props.onSelectedItemChange) props.onSelectedItemChange(data.selectedItem)
        },
        itemToString(item) {
            return item ? item.label : ""
        },
        onInputValueChange: ({ inputValue }) => {
            if(props.onInputValueChange) {
                props.onInputValueChange(inputValue, setInputItems)
            }

            if(props.items) {
                setInputItems(
                    props.items.filter((item) =>
                        item.label.toLowerCase().trim().includes(inputValue.toLowerCase().trim())
                    )
                );
            }
        }
    });
    return (
        <Flex direction="column" align="center">
            <Flex {...getComboboxProps()} direction="column" flex="1 1 auto" width="100%">
                <Flex direction="row" alignItems="baseline">
                    <ComboboxInput
                        {...getInputProps()}
                        placeholder={props.placeholder}
                        flex="0 0 auto"
                        onChange={data => {
                            setInputValue(data.target.value)
                        }}
                        onFocus={() => {
                            openMenu()
                        }}
                    />
                </Flex>
                <ComboboxList
                    isOpen={isOpen}
                    {...getMenuProps()}
                    flex={1}
                    overflowY="auto"
                    mt={0}
                >
                    {(inputItems || []).map((item, index) => (
                        <ComboboxItem
                            {...getItemProps({ item, index })}
                            itemIndex={index}
                            highlightedIndex={highlightedIndex}
                            key={index}
                        >
                            {item.label}
                        </ComboboxItem>
                    ))}
                </ComboboxList>
            </Flex>
        </Flex>
    );
}
