import {
    Box,
    Flex,
    Avatar,
    HStack,
    Link,
    IconButton,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    Center,
    Container,
    useDisclosure,
    useColorModeValue,
    Stack,
} from '@chakra-ui/react'
import md5 from "md5"
import {HiLogout, HiMenu, HiQuestionMarkCircle, HiUser, HiX} from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'

const Links = [
    //{ title: "Inventar", to: "/dashboard" },
    //{ title: "Bewertungen", to: "/dashboard" },
    //{ title: "Versand", to: "/dashboard" }
]

const NavLink = ({ children, to }) => {
    const navigate = useNavigate()

    return <Link
        px={2}
        py={1}
        rounded={'md'}
        _hover={{
            textDecoration: 'none',
            bg: useColorModeValue('gray.200', 'gray.700'),
        }}
        onClick={() => navigate(to)}>
        {children}
    </Link>
}

export default function Simple({ children }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate = useNavigate()

    return (
        <>
            <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
                <Center>
                    <Container maxW="1280px">
                        <Flex h={24} alignItems={'center'} justifyContent={'space-between'}>
                            {/*<IconButton
                                size={'md'}
                                icon={isOpen ? <HiX size={30} /> : <HiMenu size={30} />}
                                aria-label={'Open Menu'}
                                display={{ lg: 'none', md: "none", sm: "flex" }}
                                onClick={isOpen ? onClose : onOpen}
                                style={{ justifyContent: "center", marginLeft: -12 }}
                            />*/}
                            <HStack spacing={8} alignItems={'center'}>
                                <Box><img style={{ height: "50px" }} src="/logo.svg" /></Box>
                                <HStack
                                    as={'nav'}
                                    spacing={4}
                                    display={{ base: 'none', md: 'flex' }}>
                                    {Links.map((link, index) => (
                                        <NavLink key={index} to={link.to}>{link.title}</NavLink>
                                    ))}
                                </HStack>
                            </HStack>
                            <Flex alignItems={'center'}>
                                <Menu placement="bottom-end">
                                    <MenuButton
                                        as={Button}
                                        rounded={'full'}
                                        variant={'link'}
                                        cursor={'pointer'}
                                        minW={0}>
                                        <Avatar
                                            size={'md'}
                                            src={`https://www.gravatar.com/avatar/${md5(global.WatchStorage.session.email.toLowerCase().trim())}?d=mp`}
                                        />
                                    </MenuButton>
                                    <MenuList>
                                        {/*<MenuItem isDisabled onClick={() => navigate("/profile")}><HiUser style={{ width: 16, marginRight: 8 }} /> Profil</MenuItem>*/}
                                        <MenuItem onClick={() => window.open("http://uhrenkapital.de/", "_blank")}><HiQuestionMarkCircle style={{ width: 16, marginRight: 8 }} /> Mehr erfahren</MenuItem>
                                        <MenuDivider />
                                        <MenuItem onClick={() => {
                                            document.cookie = 'Authorization=; Max-Age=0'
                                            navigate("/login")
                                        }}><HiLogout style={{ width: 16, marginRight: 8 }} /> Abmelden</MenuItem>
                                    </MenuList>
                                </Menu>
                            </Flex>
                        </Flex>
                    </Container>
                </Center>
                {isOpen ? (
                    <Box pb={4} display={{ md: 'none' }}>
                        <Stack as={'nav'} spacing={4}>
                            {Links.map((link, index) => (
                                <NavLink key={index} to={link.to}>{link.title}</NavLink>
                            ))}
                        </Stack>
                    </Box>
                ) : null}
            </Box>
            <Box p={4}>
                <Center>
                    <Container maxW="1280px">
                        {children}
                    </Container>
                </Center>
            </Box>
        </>
    );
}
