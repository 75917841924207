import React from "react"
import {
    Container,
    Box,
    useBreakpointValue,
    useColorModeValue,
    Heading,
    Stack,
    FormControl,
    Text,
    Input,
    FormLabel,
    Button,
    HStack,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from '@chakra-ui/react'
import { HiLogin, HiPaperAirplane, HiX } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

export default () => {
    let navigate = useNavigate()

    const [ error, setError ] = React.useState(null)
    const [ email, setEmail ] = React.useState(null)
    const [ open, setOpen ] = React.useState(false)
    const [ successOpen, setSuccessOpen ] = React.useState(false)
    const successCancelRef = React.useRef()
    const cancelRef = React.useRef()

    return <>
        <AlertDialog
            isOpen={open}
            leastDestructiveRef={cancelRef}
            onClose={() => setOpen(false)}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Passwort zurücksetzen fehlgeschlagen
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {error}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button leftIcon={<HiX />} ref={cancelRef} onClick={() => setOpen(false)}>
                            Schließen
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
        <AlertDialog
            isOpen={successOpen}
            leastDestructiveRef={successCancelRef}
            onClose={() => {
                setSuccessOpen(false)
                navigate("/login")
            }}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Passwort zurücksetzen angefordert
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Sie erhalten in Kürze eine E-Mail, in welcher sich ein Link zum Zurücksetzen Ihres Passworts befindet.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button leftIcon={<HiLogin />} ref={successCancelRef} onClick={() => {
                            setSuccessOpen(false)
                            navigate("/login")
                        }}>
                            Zurück zur Anmeldung
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
        <Container maxW="100%" h="100vh" bg="#E2E8F0">
            <Container maxW="lg" h="100vh" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
                <Stack spacing="8">
                    <Stack spacing="6">
                        <img src="/logo.svg" />
                        <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                            <Heading size={useBreakpointValue({ base: 'xs', md: 'sm' })}>
                                In Ihrem WatchStorage Konto anmelden
                            </Heading>
                            <HStack spacing="1" justify="center">
                                <Text color="muted">Lassen Sie sich einen Link per E-Mail zusenden, um Ihr Passwort zurückzusetzen.</Text>
                            </HStack>
                            <Button variant="link" colorScheme="blue" onClick={() => navigate("/login")}>
                                Zurück zur Anmeldung
                            </Button>
                        </Stack>
                    </Stack>
                    <Box
                        py={{ base: '0', sm: '8' }}
                        px={{ base: '4', sm: '10' }}
                        bg="#ffffff"
                        boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
                        borderRadius={{ base: 'none', sm: 'xl' }}
                    >
                        <Stack spacing="6">
                            <Stack spacing="5">
                                <FormControl isRequired>
                                    <FormLabel htmlFor="email">E-Mail-Adresse</FormLabel>
                                    <Input id="email" type="email" required value={email || ""} onChange={e => setEmail(e.target.value)} />
                                </FormControl>
                            </Stack>
                            <Stack spacing="6">
                                <Button leftIcon={<HiPaperAirplane />} colorScheme="blue" disabled={!email} onClick={() => {
                                    fetch(window.watchstorage.backendUrl + "/api/v1/auth/reset-password", {
                                        method: "POST",
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            email: email.toLowerCase()
                                        })
                                    }).then(result => {
                                        if(result.status === 400) {
                                            setError("Bitte überprüfen Sie Ihre Eingaben auf Richtigkeit und versuchen Sie es erneut.")
                                            setOpen(true)
                                        } else if(result.status === 404) {
                                            setError("Es konnte kein Konto unter der angegebenen E-Mail-Adresse gefunden werden.")
                                            setOpen(true)
                                        } else if(result.status === 201) {
                                            setSuccessOpen(true)
                                            return result.text()
                                        }

                                        return null
                                    }).catch(e => {
                                        console.log(e)
                                        setError("Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.")
                                        setOpen(true)
                                    })
                                }}>Passwort zurücksetzen</Button>
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>
            </Container>
        </Container>
    </>
}
